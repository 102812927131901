import * as React from 'react';
import { IAllocation, IFullBookedTimeEntry } from '../../models';
import { RingGraph } from '../RingGraph/RingGraph';

interface ITimeEntryRingGraphProps {
  bookedTimeEntries: IFullBookedTimeEntry[]
}

interface IDataPoint {
  label: string
  value: number
}

export const TimeEntryRingGraph: React.SFC<ITimeEntryRingGraphProps> = ({ bookedTimeEntries }) => {
  const points: { [key: string]: IDataPoint } = {}

  for (const e of bookedTimeEntries) {
    const key = getKey(e.allocation)
    const point = points[key] || { label: key, value: 0 }
    point.value += e.hours
    points[key] = point
  }

  const data = []
  for (const key in points) {
    data.push(points[key])
  }

  return <RingGraph data={data.sort(sortByValue)} />
}

function getKey(allocation: IAllocation) {
  if (allocation.isIntertech) {
    return `Intertech - ${allocation.task}`
  }
  return `${allocation.client} - ${allocation.project}`
}

function sortByValue(p1: IDataPoint, p2: IDataPoint) {
  if (p1.value < p2.value) { return 1 }
  if (p1.value > p2.value) { return -1 }
  return 0
}