// tslint:disable:jsx-no-lambda
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import * as React from 'react';
import { IFullBookedTimeEntry } from '../../models';
import { isReadOnly, timeEntryRangeHours } from '../../utils/timeEntryUtils';
import { CopyLastWorkdayButton } from '../CopyLastWorkdayButton';
import { QuickEntryButton } from '../QuickEntryButton';
import { TimesheetAllocation } from '../TimesheetAllocation/TimesheetAllocation';
import './Timesheet.scss';
import { TimesheetCellHours } from './TimesheetCells';
import { TimesheetRowDay } from './TimesheetRows';

interface ITimesheetBodyDayProps {
  bookedTimeEntries: IFullBookedTimeEntry[]
  endDate: string
  selectedDate: string
  startDate: string
  onClickNewEntry?: () => void
  onClickRow?: (bookedTimeEntry: IFullBookedTimeEntry) => void
}

export class TimesheetBodyDay extends React.Component<ITimesheetBodyDayProps> {
  handleClickNewEntry = () => {
    const { onClickNewEntry } = this.props
    if (onClickNewEntry) {
      onClickNewEntry()
    }
  }

  handleClickRow = (bookedTimeEntry: IFullBookedTimeEntry) => {
    const { onClickRow } = this.props
    if (onClickRow && !bookedTimeEntry.readonly) {
      onClickRow(bookedTimeEntry)
    }
  }

  render() {
    const { bookedTimeEntries, selectedDate } = this.props
    const dailyEntries = bookedTimeEntries.filter(e => e.date === selectedDate)
    const dailyHours = timeEntryRangeHours(dailyEntries)
    const submitted = isReadOnly(bookedTimeEntries)

    return (
      <div className='timesheet-body timesheet-body-day'>
        {dailyEntries.length === 0 &&
          <React.Fragment>
            <Typography
              align='center'
              className='no-entries'
              variant='subheading'
            >
              No entries for today
            </Typography>
            {!submitted &&
              <React.Fragment>
                <QuickEntryButton entryType='vacation' className='quick-entry' />
                <QuickEntryButton entryType='sick' className='quick-entry' />
                <CopyLastWorkdayButton />
              </React.Fragment>
            }
          </React.Fragment>
        }
        <div className='timesheet-body-rows'>
          {this.renderRows(dailyEntries)}
        </div>
        <div className={classnames('timesheet-row timesheet-row-total', { submitted })}>
          <TimesheetCellHours hours={dailyHours.totalHours} />
          {!submitted &&
            <button className='btn timesheet-new-entry' onClick={this.handleClickNewEntry}>+ New Entry</button>
          }
        </div>
        <TimesheetAllocation bookedTimeEntries={bookedTimeEntries} />
      </div>
    )
  }

  renderRows(dailyEntries: IFullBookedTimeEntry[]) {
    return dailyEntries.map(e => (
      <TimesheetRowDay
        key={e.id}
        bookedTimeEntry={e}
        onClick={this.handleClickRow}
      />
    ))
  }
}