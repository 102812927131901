import { IBookedTimeEntry, IFullBookedTimeEntry } from '../models';

/**
 * Get total billable and non-billable hours for a range of time entries.
 * @param {BookedTimeEntry[]} bookedTimeEntries
 */
export function timeEntryRangeHours(bookedTimeEntries: IFullBookedTimeEntry[] = []) {
  return bookedTimeEntries.reduce((hours, e) => {
    hours.totalHours += e.hours
    if (e.allocation.billable) {
      hours.billableHours += e.hours
    } else {
      hours.nonBillableHours += e.hours
    }
    return hours
  }, { billableHours: 0, nonBillableHours: 0, totalHours: 0 })
}

/**
 * If any time entry in the range is read-only then assume that
 * the entire range is read-only.
 * @param {BookedTimeEntry[]} bookedTimeEntries
 */
export function isReadOnly(bookedTimeEntries: IBookedTimeEntry[] = []) {
  return bookedTimeEntries.some(e => e.readonly)
}