import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { setLastLocation } from '../../utils/storage';

export interface ISetLastLocationProps extends RouteComponentProps<{}> {
}

class SetLastLocation extends React.Component<ISetLastLocationProps, any> {
  componentWillReceiveProps(nextProps: ISetLastLocationProps) {
    const { location } = this.props
    const { location: nextLocation } = nextProps
    if (location !== nextLocation) {
      setLastLocation(nextLocation)
    }
  }

  public render() {
    return null
  }
}

export default withRouter(SetLastLocation)