import { Moment } from 'moment';
import { getWeek, toMoment } from './utils/dateUtils';

export type TimesheetMode = 'day' | 'week'

export type MomentConvertable =
  | string
  | number
  | Date
  | Moment
  | undefined

export interface IDateRange {
  readonly selectedDate: string
  readonly startDate: string
  readonly endDate: string
}

export interface IUser {
  name?: string
  email?: string
  allocations?: IAllocation[]
  bookedTimeEntries?: IFullBookedTimeEntry[]
  pto: IPto
}

export interface IAllocation {
  id: string
  client: string
  project?: string
  task?: string
  active?: boolean
  billable?: boolean
  isIntertech?: boolean
}

export interface IPto {
  vacationTime: {
    granted: number
    used: number
    carriedOver: number
    scheduledNotTaken: number
  },
  sickTime: {
    granted: number
    used: number
    carriedOver: number
  }
}

export interface IBookedTimeEntry {
  id: number
  date: string
  hours: number
  note?: string
  readonly: boolean
  allocation?: IAllocation
}

export interface IFullBookedTimeEntry extends IBookedTimeEntry {
  allocation: IAllocation
}

/**
 * Represents a week.
 */
export class Week implements IDateRange {
  // tslint:disable-next-line:variable-name
  readonly __typename = 'Week'
  readonly startDate: string
  readonly endDate: string
  readonly selectedDate: string

  constructor(selectedDate?: MomentConvertable) {
    const week = getWeek(toMoment(selectedDate))
    this.startDate = week.startDate
    this.endDate = week.endDate
    this.selectedDate = week.selectedDate
  }
}