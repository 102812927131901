import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { withClientState } from 'apollo-link-state';
import { Week } from '../models';
import { logout } from './sessionUtils';

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      // HACK: Hacking this client-side for now. The API should have caught the 401 from the time entry backend and relayed that to us.
      const unauthorized = (message || '').indexOf('401 - Unauthorized') > -1
      if (unauthorized) {
        logout()
      }

      if (process.env.env !== 'production') {
        // tslint:disable-next-line:no-console - removed in production build
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      }
    })
  }
  if (networkError) {
    if ((networkError as any).statusCode === 401) {
      logout()
    }

    if (process.env.env !== 'production') {
      // tslint:disable-next-line:no-console - removed in production build
      console.log(`[Network error]: ${networkError}`)
    }
  }
})

const httpLink = new HttpLink({
  uri: '/api/graphql'
})

const inMemoryCache = new InMemoryCache()

const defaults = {
  dateRange: new Week(),
  timesheetMode: 'day'
}

const stateLink = withClientState({
  cache: inMemoryCache,
  defaults,
  resolvers: {
  }
})

export const apolloClient = new ApolloClient({
  cache: inMemoryCache,
  link: ApolloLink.from([
    authLink,
    errorLink,
    stateLink,
    httpLink
  ])
})
