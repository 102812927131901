import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './components/App';
import { AppInsightsErrorBoundary } from './components/AppInsightsErrorBoundary';
import { Auth } from './components/Auth';
import registerServiceWorker from './registerServiceWorker';
import { withTheme } from './theme';
import { apolloClient } from './utils/apollo';

const Root = withTheme(() => (
  <AppInsightsErrorBoundary>
    <ApolloProvider client={apolloClient}>
      <Router>
        <Auth>
          <App />
        </Auth>
      </Router>
    </ApolloProvider>
  </AppInsightsErrorBoundary>
))

ReactDOM.render(<Root />,
  document.getElementById('root')
)

registerServiceWorker()
