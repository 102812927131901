import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DateRange from '@material-ui/icons/DateRange';
import Today from '@material-ui/icons/Today';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { IDateRangeProps, withDateRange } from '../WithDateRange';
import { AccountMenu } from './AccountMenu';

const TodayButton = withDateRange(({ toToday }: IDateRangeProps) => (
  <IconButton
    aria-label='today'
    color='inherit'
    onClick={toToday}
  >
    <Today />
  </IconButton>
))

const ThisWeekButton = withDateRange(({ toThisWeek }: IDateRangeProps) => (
  <IconButton
    aria-label='this week'
    color='inherit'
    onClick={toThisWeek}
  >
    <DateRange />
  </IconButton>
))

const styles = (theme: Theme) => createStyles({
  fill: {
    flexGrow: 1
  }
})

interface ITopBarProps extends WithStyles<typeof styles> { }

class TopBar extends React.Component<ITopBarProps> {
  dashboardTitle = () => <Typography variant='title'>Dashboard</Typography>
  dailyTitle = () => <Typography variant='title'>Daily Timesheet</Typography>
  weeklyTitle = () => <Typography variant='title'>Weekly Timesheet</Typography>
  reportsTitle = () => <Typography variant='title'>Reports</Typography>

  render() {
    const { classes } = this.props
    return (
      <AppBar
        color='inherit'
        position='absolute'
        elevation={0}
      >
        <Toolbar>
          <Switch>
            <Route path='/dashboard' render={this.dashboardTitle} />
            <Route path='/timesheet/day' render={this.dailyTitle} />
            <Route path='/timesheet/week' render={this.weeklyTitle} />
            <Route path='/reports' render={this.reportsTitle} />
          </Switch>
          <div className={classes.fill} />
          <Switch>
            <Route path='/timesheet/week' component={ThisWeekButton} />
            <Route component={TodayButton} />
          </Switch>
          <AccountMenu />
        </Toolbar>
      </AppBar >
    )
  }
}

export default withStyles(styles)(TopBar)