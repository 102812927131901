// tslint:disable:jsx-no-lambda
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import gql from 'graphql-tag';
import * as React from 'react';
import { Mutation, MutationFn, MutationResult, MutationUpdaterFn } from 'react-apollo';
import { IFullBookedTimeEntry } from '../../models';
import { toMoment } from '../../utils/dateUtils';
import { ActivityButton } from '../ActivityButton';
import { FULL_BOOKED_TIME_ENTRY_FRAGMENT } from '../TimeEntriesQuery';
import { ITimeEntriesQueryData, TIME_ENTRIES_QUERY } from '../TimeEntriesQuery/TimeEntriesQuery';
import { IDateRangeProps, withDateRange } from '../WithDateRange';

const ISO_YEAR_WEEK = 'GGGG-[W]WW'

const COPY_LAST_WEEK_MUTATION = gql`
mutation copyWeek($sourceIsoWeekNumber: String!, $destinationIsoWeekNumber: String!) {
  copyWeek(sourceIsoWeekNumber: $sourceIsoWeekNumber, destinationIsoWeekNumber: $destinationIsoWeekNumber) {
    ...FullBookedTimeEntry
  }
}
${FULL_BOOKED_TIME_ENTRY_FRAGMENT}
`

interface ICopyLastWeekData {
  copyWeek: IFullBookedTimeEntry[]
}

interface ICopyLastWeekVariables {
  sourceIsoWeekNumber: string
  destinationIsoWeekNumber: string
}

type CopyLastWeekMutationFn = MutationFn<ICopyLastWeekData, ICopyLastWeekVariables>
type CopyLastWeekMutationResult = MutationResult<ICopyLastWeekData>

const styles = (theme: Theme) => createStyles({
  root: {
    borderRadius: 0,
    backgroundColor: theme.palette.grey['300']
  }
})

interface ICopyLastWeekButtonProps extends IDateRangeProps, WithStyles<typeof styles> {
  className?: string
}

class CopyLastWeekButton extends React.Component<ICopyLastWeekButtonProps> {
  render() {
    return (
      <Mutation mutation={COPY_LAST_WEEK_MUTATION} update={this.updateCache}>
        {this.renderButton}
      </Mutation>
    )
  }

  renderButton = (copy: CopyLastWeekMutationFn, { loading }: CopyLastWeekMutationResult) => {
    const { classes, className, dateRange: { selectedDate } } = this.props
    const variables: ICopyLastWeekVariables = {
      sourceIsoWeekNumber: toMoment(selectedDate).subtract(1, 'week').format(ISO_YEAR_WEEK),
      destinationIsoWeekNumber: toMoment(selectedDate).format(ISO_YEAR_WEEK),
    }

    const rootClassName = classNames(classes.root, className)

    return (
      <ActivityButton
        busy={loading}
        className={rootClassName}
        onClick={() => copy({ variables })}
      >
        Copy Last Week
      </ActivityButton>
    )
  }

  updateCache: MutationUpdaterFn<ICopyLastWeekData> = (cache, { data }) => {
    const { dateRange: { startDate, endDate } } = this.props
    const query = TIME_ENTRIES_QUERY
    const variables = { startDate, endDate }

    // Read the current entries for TIME_ENTRIES_QUERY 
    const results = cache.readQuery<ITimeEntriesQueryData>({
      query,
      variables
    })

    if (!results || !data) {
      return
    }

    const next = {
      ...results,
      my: {
        ...results.my,
        bookedTimeEntries: [...results.my.bookedTimeEntries, ...data.copyWeek]
      }
    }

    cache.writeQuery({
      data: next,
      query,
      variables
    })
  }
}

export default withStyles(styles)(withDateRange(CopyLastWeekButton))