import { Location, LocationState } from "history";
import { hoursUntilNow, now } from "./dateUtils";

const TOKEN_KEY = 'token'
const USERNAME_KEY = 'username'
const LAST_LOCATION_PATH = 'lastPath'

export function getToken() {
  return localStorage.getItem(TOKEN_KEY)
}

export function setToken(token: string) {
  localStorage.setItem(TOKEN_KEY, token)
}

export function clearToken() {
  localStorage.removeItem(TOKEN_KEY)
}

export function getUsername() {
  return localStorage.getItem(USERNAME_KEY)
}

export function setUsername(username: string) {
  localStorage.setItem(USERNAME_KEY, username)
}

export function getLastLocation(): Location<{}> | null {
  const value = localStorage.getItem(LAST_LOCATION_PATH)
  try {
    if (value) {
      const location = JSON.parse(value)
      const age = hoursUntilNow(location.now)
      if (age > 1) {
        // Only remember the previously selected date for one hour otherwise
        // set date to the current day
        location.search = ''
      }
      return location
    }
  } catch (error) {
    // Ignore the error and return null
  }
  return null
}

export function setLastLocation(location: Location<LocationState>) {
  const loc = {
    ...location,
    now: now()
  }
  localStorage.setItem(LAST_LOCATION_PATH, JSON.stringify(loc))
}