import classNames from 'classnames';
import * as React from 'react';
import { IFullBookedTimeEntry } from '../../models';
import { isReadOnly, timeEntryRangeHours } from '../../utils/timeEntryUtils';
import { AllocationBar } from '../AllocationBar';
import { AllocationSummary } from '../AllocationSummary';
import './TimesheetAllocation.scss';

interface ITimesheetAllocationProps {
  bookedTimeEntries: IFullBookedTimeEntry[]
  compact: boolean
}

export class TimesheetAllocation extends React.Component<ITimesheetAllocationProps> {
  static defaultProps = {
    compact: false
  }

  render() {
    const { bookedTimeEntries, compact } = this.props
    const weeklyHours = timeEntryRangeHours(bookedTimeEntries)
    const isSubmitted = isReadOnly(bookedTimeEntries)

    return (
      <div className={classNames('timesheet-allocation', { compact })}>
        <AllocationSummary
          billableHours={weeklyHours.billableHours}
          nonBillableHours={weeklyHours.nonBillableHours}
          isSubmitted={isSubmitted}
        />
        <AllocationBar
          totalHours={weeklyHours.totalHours}
          compact={compact}
        />
      </div>
    )
  }
}
