import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import AccessTime from '@material-ui/icons/AccessTime';
import DonutSmall from '@material-ui/icons/DonutSmall';
import Timelapse from '@material-ui/icons/Timelapse';
import Timeline from '@material-ui/icons/Timeline';
import classNames from 'classnames';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { today } from '../../utils/dateUtils';
import { BottomBarAction } from './BottomBarAction';

const styles = (theme: Theme) => createStyles({
  root: {}
})

interface IBottomBarProps extends WithStyles<typeof styles>, RouteComponentProps<{}> {
  className?: string
}

class BottomBar extends React.Component<IBottomBarProps> {

  handleValueChanged = (_: any, value: string) => {
    this.setState({ value })
  }

  render() {
    const { location, classes, className } = this.props
    const search = location.search || `date=${today()}`
    const rootClassName = classNames(className, classes.root)

    return (
      <BottomNavigation
        className={rootClassName}
        onChange={this.handleValueChanged}
        showLabels
        value={location.pathname}
      >
        <BottomBarAction
          icon={<DonutSmall />}
          label='Dashboard'
          search={search}
          value='/dashboard'
        />
        <BottomBarAction
          icon={<AccessTime />}
          label='Daily'
          search={search}
          value='/timesheet/day'
        />
        <BottomBarAction
          icon={<Timelapse />}
          label='Weekly'
          search={search}
          value='/timesheet/week'
        />
        <BottomBarAction
          icon={<Timeline />}
          label='Reports'
          search={search}
          value='/reports'
        />
      </BottomNavigation>
    )
  }
}

export default withStyles(styles)(withRouter(BottomBar))