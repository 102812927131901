import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import * as React from 'react';
import './AllocationBar.scss';

const styles = (theme: Theme) => createStyles({
  root: {},
  value: {
    marginLeft: theme.spacing.unit
  }
})

interface IAllocationBarProps extends WithStyles<typeof styles> {
  className?: string
  totalHours: number
  compact?: boolean
}

class AllocationBar extends React.Component<IAllocationBarProps> {
  static defaultProps = {
    compact: false,
    totalHours: 0,
  }

  render() {
    const { totalHours, compact, className: classNameProp, classes } = this.props
    const percent = Math.min(totalHours / 40, 1)
    const width = `${percent * 100}%`

    const className = classNames(
      'allocation-bar',
      classes.root,
      classNameProp,
      { compact }
    )

    return (
      <div className={className}>
        Weekly Allocation
        <div className='display'>
          <div className='bars'>
            <div className='bar' />
            <div className='bar active' style={{ width }} />
            {!compact &&
              <div className='knob-container'>
                <div className={classNames('knob', { active: percent > 0 })} style={{ left: width }}>
                  {Math.floor(totalHours)}
                </div>
              </div>
            }
          </div>
          <Typography variant='title' component='div' className={classes.value}>
            40
          </Typography>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(AllocationBar)
