import Button from '@material-ui/core/Button';
import * as React from "react";
import { AppInsights } from "../../utils/appInsights";

interface IAppInsightsErrorBoundaryProps {
  name?: string
}

interface IAppInsightsErrorBoundaryState {
  hasError: boolean
}

export class AppInsightsErrorBoundary extends React.Component<IAppInsightsErrorBoundaryProps, IAppInsightsErrorBoundaryState> {
  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  state = {
    hasError: false
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    const { name } = this.props
    AppInsights.trackException(
      error,
      name,
      {
        componentStack: info.componentStack
      }
    )
  }

  render() {
    const { hasError } = this.state
    if (hasError) {
      return (
        <div style={{ padding: '1rem' }}>
          <h1>
            An error has occurred and will be reported.
          </h1>
          <Button
            color='primary'
            onClick={this.handleReloadClick}
            variant='raised'
          >
            Restart App
          </Button>
        </div>
      )
    }

    return this.props.children
  }

  private handleReloadClick = () => {
    AppInsights.flush()
    window.location.reload()
  }
}