// tslint:disable:jsx-no-lambda
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { MutationUpdaterFn } from 'react-apollo';
import { IFullBookedTimeEntry } from '../../models';
import { toMoment } from '../../utils/dateUtils';
import { ActivityButton } from '../ActivityButton';
import { DeleteTimeEntryMutation, IDeleteTimeEntryData } from '../DeleteTimeEntryMutation';
import { ErrorMessage } from '../Message/Message';
import { ISaveTimeEntryVariables, SaveTimeEntryMutation, SaveTimeEntryMutationFn, SaveTimeEntryMutationResult, ISaveTimeEntryData } from '../SaveTimeEntryMutation';
import { Spinner } from '../Spinner/Spinner';
import { ITimeEntriesQueryData, TIME_ENTRIES_QUERY } from '../TimeEntriesQuery/TimeEntriesQuery';
import { TimeEntryForm } from '../TimeEntryForm';
import { AllocationsQuery, IAllocationsQueryResult } from './AllocationsQuery';
import ConfirmDeleteButton from './ConfirmDeleteButton';

const DATE_FORMAT = 'ddd MMM D, YYYY'

const styles = (theme: Theme) => createStyles({
  root: {}
})

interface ITimeEntryDialogProps extends WithStyles<typeof styles> {
  bookedTimeEntry: IFullBookedTimeEntry | null
  endDate: string
  selectedDate: string
  startDate: string
  open: boolean
  onClose: () => void
}

interface ITimeEntryDialogState {
  variables?: ISaveTimeEntryVariables
}

class TimeEntryDialog extends React.Component<ITimeEntryDialogProps, ITimeEntryDialogState> {
  constructor(props: ITimeEntryDialogProps) {
    super(props)
    this.state = {}
  }

  handleOnChange = (variables: ISaveTimeEntryVariables) => {
    this.setState({ variables })
  }

  render() {
    return (
      <SaveTimeEntryMutation update={this.updateCache}>
        {this.renderDialog}
      </SaveTimeEntryMutation>
    )
  }

  /**
   * Update the Apollo cache with the saved time entry.
   */
  updateCache: MutationUpdaterFn<ISaveTimeEntryData> = (cache, { data }) => {
    if (!data) {
      return
    }

    const { startDate, endDate, bookedTimeEntry } = this.props
    if (bookedTimeEntry) {
      // If a time entry was passed as a prop this is an update. 
      // The entry in the cache will be updated automatically.
      return
    }

    const query = TIME_ENTRIES_QUERY
    const variables = { startDate, endDate }

    // Read the current entries for TIME_ENTRIES_QUERY 
    let results = cache.readQuery<ITimeEntriesQueryData>({
      query,
      variables
    })

    if (!results) {
      return
    }

    // We need to pass a new object or the cache won't properly update
    // Components watching this query will not re-render
    results = {
      ...results,
      my: {
        ...results.my,
        bookedTimeEntries: [...results.my.bookedTimeEntries, data.upsertBookedTimeEntry]
      }
    }

    // Write a new array of time entries for this query
    cache.writeQuery({
      data: results,
      query,
      variables
    })
  }

  /**
   * Render the UI for the dialog.
   * @param save The save function is provided by SaveTimeEntryMutation.
   */
  renderDialog = (save: SaveTimeEntryMutationFn, { error, loading: saving }: SaveTimeEntryMutationResult) => {
    if (error) {
      return this.renderErrorDialog(error)
    }

    // const { bookedTimeEntry, selectedDate, onClose } = this.props
    const { variables } = this.state
    const isValid = this.variablesAreValid(variables)

    const handleSave = (e: React.MouseEvent) => {
      e.preventDefault()
      save({ variables })
        .then(() => onClose(), () => onClose())
    }

    const {
      bookedTimeEntry,
      onClose,
      open,
      selectedDate,
    } = this.props

    const deleteVariables = {
      entryId: bookedTimeEntry ? bookedTimeEntry.id : -1
    }

    return (
      <DeleteTimeEntryMutation update={this.deleteFromCache}>
        {(deleteFn) => {
          return (
            <Dialog
              open={open}
              onClose={onClose}
              fullWidth={true}
              aria-labelledby='form-dialog-title'
            >
              <DialogTitle id='form-dialog-title'>
                {bookedTimeEntry ? 'Edit' : 'New'} Time Entry
              </DialogTitle>
              <DialogContent>
                <Typography variant='subheading'>
                  {toMoment(selectedDate).format(DATE_FORMAT)}
                </Typography>
                <AllocationsQuery>
                  {this.renderForm}
                </AllocationsQuery>
              </DialogContent>
              <DialogActions>
                {bookedTimeEntry &&
                  <ConfirmDeleteButton onClick={() => {
                    deleteFn({ variables: deleteVariables })
                      .then(() => onClose())
                  }}
                  />
                }
                <Button
                  color='primary'
                  disabled={saving}
                  onClick={onClose}
                >
                  Close
                </Button>
                <ActivityButton
                  busy={saving}
                  color='primary'
                  disabled={saving || !isValid}
                  onClick={handleSave}
                  type='submit'
                  variant='contained'
                >
                  Save
                </ActivityButton>
              </DialogActions>
            </Dialog>
          )
        }}
      </DeleteTimeEntryMutation>
    )
  }

  renderForm = ({ data, loading, error }: IAllocationsQueryResult) => {
    if (error) {
      return this.renderErrorDialog(error)
    }
    if (loading) {
      return <Spinner />
    }

    const allocations = (data && data.my.allocations) || []
    const { bookedTimeEntry, selectedDate, onClose } = this.props

    return (
      <TimeEntryForm
        allocations={allocations}
        bookedTimeEntry={bookedTimeEntry}
        date={selectedDate}
        onChange={this.handleOnChange}
        onClose={onClose}
      />
    )
  }

  deleteFromCache: MutationUpdaterFn<IDeleteTimeEntryData> = (cache, { data }) => {
    const { startDate, endDate } = this.props
    const query = TIME_ENTRIES_QUERY
    const variables = { startDate, endDate }
    const results = cache.readQuery<ITimeEntriesQueryData>({
      query,
      variables
    })

    if (!results || !data) {
      return
    }

    const entry = results.my.bookedTimeEntries.find(e => e.id === data.deleteBookedTimeEntry.id)
    if (!entry) {
      return
    }

    const bookedTimeEntries = results.my.bookedTimeEntries
    const index = bookedTimeEntries.indexOf(entry)
    bookedTimeEntries.splice(index, 1)
    results.my.bookedTimeEntries = [...bookedTimeEntries]
    cache.writeQuery({
      data: results,
      query,
      variables
    })
  }

  /**
   * Display an error message if the time entry fails to save.
   */
  renderErrorDialog(error: Error) {
    const { onClose } = this.props

    // TODO: Provide a friendly error message.
    return (
      <div className='modal-dialog modal-lg time-entry-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Error</h5>
          </div>
          <div className='modal-body'>
            <ErrorMessage error={error} />
          </div>
          <div className='modal-footer'>
            <button className='btn btn-primary' onClick={onClose}>
              OK
            </button>
          </div>
        </div>
      </div >
    )
  }

  private variablesAreValid(variables?: ISaveTimeEntryVariables) {
    if (!variables) {
      return false
    }
    const updatedEntry = variables.entry
    return !!(
      updatedEntry.allocationId &&
      updatedEntry.date &&
      updatedEntry.hours &&
      updatedEntry.note
    )
  }
}

export default withStyles(styles)(TimeEntryDialog)