

import gql from 'graphql-tag';
import { Mutation, MutationFn, MutationResult } from 'react-apollo';
import { IFullBookedTimeEntry } from '../../models';
import { FULL_BOOKED_TIME_ENTRY_FRAGMENT } from '../TimeEntriesQuery';

const SAVE_TIME_ENTRY_QUERY = gql`
mutation upsert($entry: UpsertBookedTimeEntryInput!) {
	upsertBookedTimeEntry(entry: $entry) {
    ...FullBookedTimeEntry
	}
}
${FULL_BOOKED_TIME_ENTRY_FRAGMENT}
`

export interface ISaveTimeEntryData {
  upsertBookedTimeEntry: IFullBookedTimeEntry
}

export interface ISaveTimeEntryVariables {
  entry: {
    allocationId: string
    date: string
    hours: number
    id?: number
    note: string
  }
}

export type SaveTimeEntryMutationFn = MutationFn<ISaveTimeEntryData, ISaveTimeEntryVariables>

export type SaveTimeEntryMutationResult = MutationResult<ISaveTimeEntryData>

export class SaveTimeEntryMutation extends Mutation<ISaveTimeEntryData, ISaveTimeEntryVariables> {
  static defaultProps = {
    mutation: SAVE_TIME_ENTRY_QUERY
  }
}