import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import * as React from 'react';

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
})

export interface IActivityButtonProps extends WithStyles<typeof styles> {
  busy?: boolean
  className?: string
  color?: 'inherit' | 'primary' | 'secondary' | 'default'
  disabled?: boolean
  onClick?: (e: React.MouseEvent) => void
  type?: 'submit' | 'reset' | 'button'
  variant?: 'text' | 'flat' | 'outlined' | 'contained' | 'raised'
}

const ActivityButton: React.SFC<IActivityButtonProps> = props => {
  const {
    busy,
    children,
    classes,
    className,
    color,
    disabled,
    onClick,
    type,
    variant,
  } = props

  return (
    <div className={classNames(classes.root, className)}>
      <Button
        color={color}
        disabled={disabled || busy}
        fullWidth
        onClick={onClick}
        type={type}
        variant={variant}
      >
        {children}
      </Button>
      {busy && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  )
}

export default withStyles(styles)(ActivityButton)