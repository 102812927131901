import yellow from '@material-ui/core/colors/yellow';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as React from 'react';

export const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#1565c0'
    },
    secondary: yellow
  },
  shape: {
    borderRadius: 2
  }
})

export const withTheme = (Component: React.ComponentType): React.SFC<{}> =>
  () => (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Component />
    </MuiThemeProvider>
  )
