import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import classNames from 'classnames';
import * as React from 'react';
import { MomentConvertable } from '../../models';
import { toMoment } from '../../utils/dateUtils';

const singleFormat = 'dddd MMM D, YYYY'
const rangeFormat = 'ddd MMM D'
const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  current: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  label: {
    flexGrow: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
  }
})

interface IDateRangeBarProps extends WithStyles<typeof styles> {
  startDate: MomentConvertable
  endDate?: MomentConvertable
  onPrevious?: () => void
  onNext?: () => void
}

const DateRangeBar: React.SFC<IDateRangeBarProps> = ({ classes, startDate, endDate, onPrevious, onNext }) => {
  const today = toMoment()
  const start = toMoment(startDate)

  let todayInCurrentRange = false
  let text = ''
  let mode = ''

  if (endDate) {
    const end = toMoment(endDate)
    todayInCurrentRange = today.isSameOrAfter(start, 'day') && today.isSameOrBefore(end, 'day')
    text = `${start.format(rangeFormat)} - ${end.format(rangeFormat)}`
    mode = 'week'
  } else {
    todayInCurrentRange = today.isSame(start, 'day')
    text = start.format(singleFormat)
    mode = 'day'
  }

  const className = classNames(
    classes.root,
    { [classes.current]: todayInCurrentRange }
  )

  return (
    <div className={className}>
      {onPrevious &&
        <IconButton
          aria-label={`previous ${mode}`}
          color='inherit'
          onClick={onPrevious}
        >
          <ChevronLeft />
        </IconButton>
      }
      <div className={classes.label}>
        <Typography variant='subheading' color='inherit'>{text}</Typography>
      </div>
      {onNext &&
        <IconButton
          aria-label={`next ${mode}`}
          color='inherit'
          onClick={onNext}
        >
          <ChevronRight />
        </IconButton>
      }
    </div >
  )
}

export default withStyles(styles)(DateRangeBar)