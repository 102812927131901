import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import * as storage from '../../utils/storage';
import logo from './ios-appicon-120-120.png';
import { requestToken } from './loginRequests';

const styles = (theme: Theme) => createStyles({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#184688',
    marginBottom: theme.spacing.unit * 2,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    padding: theme.spacing.unit,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
    padding: `0 ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  snack: {
    marginTop: theme.spacing.unit
  },
  wrapper: {
    marginTop: theme.spacing.unit * 3,
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

interface ILoginComponentProps extends WithStyles<typeof styles> {
  onLogin: () => void
}

interface ILoginComponentState {
  busy: boolean
  waiting: boolean
  password: string
  status: string
  username: string
}

class Login extends React.Component<ILoginComponentProps, ILoginComponentState> {
  passwordInput?: HTMLInputElement
  mounted: boolean = false

  constructor(props: ILoginComponentProps) {
    super(props)
    const username = storage.getUsername() || ''
    this.state = {
      busy: false,
      waiting: false,
      password: '',
      status: '',
      username,
    }
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> =
    e => this.setState({ username: e.currentTarget.value })

  handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> =
    e => this.setState({ password: e.currentTarget.value })

  handleLoginClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    this.setState({ busy: true }, this.login)
    setTimeout(() => {
      if (this.mounted) {
        this.setState(state => ({ waiting: state.busy }))
      }
    }, 1000)
  }

  handleSnackbarClose = () => {
    this.setState({ status: '' })
  }

  setPaswordRef = (ref: HTMLInputElement) => { this.passwordInput = ref }

  login = () => {
    const { username, password } = this.state
    const { onLogin } = this.props

    storage.clearToken()

    requestToken(username, password)
      .then(token => {
        storage.setToken(token)
        storage.setUsername(username)
        onLogin()
      })
      .catch(error => {
        const status = error.message === '401'
          ? 'Username or password incorrect'
          : 'Failed to authenticate'
        this.setState({
          busy: false,
          waiting: false,
          status,
        })
      })
  }

  render() {
    const { classes } = this.props
    const { username, password, busy, waiting, status } = this.state

    return (
      <React.Fragment>
        <Snackbar
          className={classes.snack}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!status}
          autoHideDuration={5000}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{status}</span>}
        />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <div className={classes.logo}>
              <img src={logo} alt='Intertech Logo'/>
            </div>
            <Typography variant='headline'>Time Entry Login</Typography>
            <form className={classes.form} onSubmit={this.handleLoginClick}>
              <FormControl margin='normal' required fullWidth>
                <InputLabel htmlFor='email'>Email Address</InputLabel>
                <Input
                  id='email'
                  name='email'
                  autoFocus
                  autoComplete='current-email'
                  autoCapitalize='off'
                  autoCorrect='off'
                  spellCheck={false}
                  value={username}
                  onChange={this.handleUsernameChange}
                />
              </FormControl>
              <FormControl margin='normal' required fullWidth>
                <InputLabel htmlFor='password'>Password</InputLabel>
                <Input
                  name='password'
                  type='password'
                  id='password'
                  autoComplete='current-password'
                  value={password}
                  onChange={this.handlePasswordChange}
                />
              </FormControl>
              <div className={classes.wrapper}>
                <Button
                  color='primary'
                  disabled={busy}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Login
                </Button>
                {waiting && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </form>
          </Paper>
        </main>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Login)
