import Lock from '@material-ui/icons/Lock';
import * as React from 'react';
import { IFullBookedTimeEntry, MomentConvertable } from '../../models';
import { toMoment } from '../../utils/dateUtils';
import './TimesheetCells.scss';

interface ITimesheetCellTimeEntryDetailProps {
  bookedTimeEntry: IFullBookedTimeEntry
}

export const TimesheetCellTimeEntryDetail: React.SFC<ITimesheetCellTimeEntryDetailProps> = ({ bookedTimeEntry }) => {
  const allocation = bookedTimeEntry.allocation
  return (
    <div className='timesheet-cell timesheet-cell-time-entry timesheet-cell-detail'>
      {bookedTimeEntry.readonly && <Lock className='lock' />}
      <div className='timesheet-cell-client'>{allocation.client}</div>
      <div className='timesheet-cell-project'>{allocation.project} - {allocation.task}</div>
      <div className='timesheet-cell-note'>{bookedTimeEntry.note}</div>
    </div>
  )
}

interface ITimesheetCellTimeEntryProps {
  bookedTimeEntry: IFullBookedTimeEntry
}

export const TimesheetCellTimeEntry: React.SFC<ITimesheetCellTimeEntryProps> = ({ bookedTimeEntry }) => {
  const allocation = bookedTimeEntry.allocation || {}
  return (
    <div
      className='timesheet-cell timesheet-cell-time-entry'
      style={{
        flexBasis: 'auto',
        flexGrow: bookedTimeEntry.hours,
        flexShrink: bookedTimeEntry.hours,
      }}
    >
      {allocation.client} ({formatHours(bookedTimeEntry.hours)})
      {bookedTimeEntry.readonly && <Lock className='lock' />}
    </div>
  )
}

interface ITimesheetCellHoursProps {
  hours: number
}

export const TimesheetCellHours: React.SFC<ITimesheetCellHoursProps> = ({ hours }) => (
  <div className='timesheet-cell timesheet-cell-hours'>
    <div className='timesheet-cell-hours-value'>{formatHours(hours)}</div>
    Hours
  </div>
)

interface ITimesheetCellDateProps {
  date: MomentConvertable
}

export const TimesheetCellDate: React.SFC<ITimesheetCellDateProps> = ({ date }) => {
  const m = toMoment(date)
  return (
    <div className='timesheet-cell timesheet-cell-date'>
      <div>{m.format('ddd')}</div>
      {m.format('M/D')}
    </div>
  )
}

function formatHours(hours = 0) {
  return Math.floor(hours * 100) / 100
}

interface ITimesheetCellNewEntryProps {
  date: MomentConvertable
  onClick: (date: MomentConvertable) => void
}

export class TimesheetCellNewEntry extends React.Component<ITimesheetCellNewEntryProps> {
  handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    const { onClick, date } = this.props
    onClick(date)
  }

  render() {
    return (
      <div
        className='timesheet-cell timesheet-cell-new-entry'
        onClick={this.handleClick}
      >
        <div>+</div>
        add
      </div>
    )
  }
}