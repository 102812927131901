import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Swipeable from 'react-swipeable';
import { isReadOnly, timeEntryRangeHours } from '../../utils/timeEntryUtils';
import { AllocationBar } from '../AllocationBar';
import { AllocationSummary } from '../AllocationSummary';
import { DateRangeBar } from '../DateRangeBar';
import { ErrorMessage } from '../Message/Message';
import { ITimeEntriesQueryResult, TimeEntriesQuery } from '../TimeEntriesQuery/TimeEntriesQuery';
import { IDateRangeProps, withDateRange } from '../WithDateRange';
import './Dashboard.scss';
import { TimeEntryRingGraph } from './TimeEntryRingGraph';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  allocation: theme.mixins.gutters({
    marginTop: theme.spacing.unit * 2,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing.unit * 4
    }
  })
})

interface IDashboardProps extends WithStyles<typeof styles>, RouteComponentProps<{}>, IDateRangeProps { }

interface IDashboardState {
  deltaX: number
}

class Dashboard extends React.Component<IDashboardProps, IDashboardState> {
  state = {
    deltaX: 0
  }

  handleSwiping: Swipeable.OnSwipingCallback = (e, deltaX, deltaY, absX, absY, velocity) => {
    this.setState({ deltaX })
  };

  handleSwiped: Swipeable.OnSwipedCallback = (e, deltaX, deltaY, isFlick, velocity) => {
    this.setState({ deltaX: 0 })
    const { previousWeek, nextWeek } = this.props
    if (isFlick) {
      deltaX > 0
        ? nextWeek()
        : previousWeek()
    }
  }

  render() {
    const { classes, nextWeek, previousWeek, dateRange } = this.props
    const { deltaX } = this.state

    return (
      <Swipeable
        className={classes.root}
        // onSwiping={this.handleSwiping}
        // onSwiped={this.handleSwiped}
        trackMouse={true}
      >
        <div style={{
          transform: `translate3d(${-deltaX}px, 0px , 0px)`
        }}
        >
          <DateRangeBar
            endDate={dateRange.endDate}
            onNext={nextWeek}
            onPrevious={previousWeek}
            startDate={dateRange.startDate}
          />
        </div>
        <TimeEntriesQuery variables={dateRange}>
          {this.renderQuery}
        </TimeEntriesQuery>
      </Swipeable>
    )
  }

  renderQuery = ({ loading, error, data }: ITimeEntriesQueryResult) => {
    if (loading) {
      return <LinearProgress />
    }
    if (error || !data) {
      return <ErrorMessage error={error}>Error loading time entries</ErrorMessage>
    }

    const { classes } = this.props
    const weeklyHours = timeEntryRangeHours(data.my.bookedTimeEntries)
    const isSubmitted = isReadOnly(data.my.bookedTimeEntries)

    return [
      <AllocationBar
        className={classes.allocation}
        key='bar'
        totalHours={weeklyHours.totalHours}
      />,
      <AllocationSummary
        className={classes.allocation}
        key='summary'
        billableHours={weeklyHours.billableHours}
        nonBillableHours={weeklyHours.nonBillableHours}
        isSubmitted={isSubmitted}
      />,
      <TimeEntryRingGraph
        key='graph'
        bookedTimeEntries={data.my.bookedTimeEntries}
      />
    ]
  }
}

export default withStyles(styles)(withDateRange(Dashboard))