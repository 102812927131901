import * as queryString from 'query-string';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { dateSearch } from '../../utils/routingHelpers';
import { getLastLocation } from '../../utils/storage';

export interface IRedirectToLastProps extends RouteComponentProps<{}> {
  defaultPath: string
}

class RedirectToLast extends React.Component<IRedirectToLastProps, any> {
  componentDidMount() {
    const lastLocation = getLastLocation() 
    const { 
      defaultPath,
      history, 
      location, 
    } = this.props

    if (lastLocation) {
      history.replace(lastLocation)
    } else {
      const params = queryString.parse(location.search)
      history.replace({
        pathname: defaultPath,
        search: dateSearch(String(params.date)) 
      })
    }
  }

  public render() {
    return null
  }
}

export default withRouter(RedirectToLast)