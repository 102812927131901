import gql from "graphql-tag";
import { Query, QueryResult } from "react-apollo";
import { IAllocation, IUser } from '../../models';

const ALLOCATIONS_QUERY = gql`
query allocations {
  my {
    allocations(active: true)  {
      id
      client
      project
      task
      active
      billable
      isIntertech
    }
  }
}
`

interface IAllocationUser extends IUser {
  allocations: IAllocation[]
}

interface IAllocationsQueryData {
  my: IAllocationUser
}

export interface IAllocationsQueryResult extends QueryResult<IAllocationsQueryData> {
}

export class AllocationsQuery extends Query<IAllocationsQueryData> {
  static defaultProps = {
    query: ALLOCATIONS_QUERY
  }
}