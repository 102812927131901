import moment from 'moment';
import { IDateRange, MomentConvertable } from '../models';

export const ISO_SHORT = 'YYYY-MM-DD'

export function today() {
  return moment().startOf('day').format(ISO_SHORT)
}

export function now() {
  return moment.utc().toISOString()
}

export function hoursUntilNow(value: string) {
  const m = moment(value, moment.ISO_8601)
  if (!m.isValid()) {
    return Number.MAX_VALUE
  }
  const hours = moment().diff(m, 'hours')
  return hours
}

/**
 * Returns a Moment for the current week's time entry due date and time.
 */
export function dueDateTime() {
  return moment()
    .endOf('isoWeek')
    .hours(18)
    .minutes(0)
}

/**
 * Return an array of string dates.
 * e.g.) ['2018-01-01', '2018-01-02', ...]
 */
export function dateArray(startDate: MomentConvertable, endDate: MomentConvertable) {
  const dates: string[] = []
  const date = toMoment(startDate)
  const end = toMoment(endDate)

  while (date.isSameOrBefore(end)) {
    dates.push(date.format(ISO_SHORT))
    date.add(1, 'day')
  }

  return dates
}

/**
 * Move the previous day in a weekly date range.
 */
export function previousDay(selectedDate: MomentConvertable) {
  selectedDate = toMoment(selectedDate).startOf('day').subtract(1, 'day')
  return getWeek(selectedDate)
}

/**
 * Move to the next day in a weekly date range.
 */
export function nextDay(selectedDate: MomentConvertable) {
  selectedDate = toMoment(selectedDate).startOf('day').add(1, 'day')
  return getWeek(selectedDate)
}

/**
 * Move the previous weekly range
 */
export function previousWeek(selectedDate: MomentConvertable) {
  selectedDate = toMoment(selectedDate).startOf('day').subtract(1, 'week')
  return getWeek(selectedDate)
}

/**
 * Move the next weekly range.
 */
export function nextWeek(selectedDate: MomentConvertable) {
  selectedDate = toMoment(selectedDate).startOf('day').add(1, 'week')
  return getWeek(selectedDate)
}

/**
 * Returns the first day of the week.
 * @param selectedDate An optional target date. Defaults to today.
 */
export function endOfWeek(selectedDate?: MomentConvertable) {
  return toMoment(selectedDate).endOf('isoWeek').format(ISO_SHORT)
}

/**
 * Returns the last day of the week.
 * @param selectedDate An optional target date. Defaults to today.
 */
export function startOfWeek(selectedDate?: MomentConvertable) {
  return toMoment(selectedDate).startOf('isoWeek').format(ISO_SHORT)
}

/**
 * Get the first and last days of the week for the selected date.
 */
export function getWeek(selectedDate: MomentConvertable): IDateRange {
  return {
    endDate: endOfWeek(selectedDate),
    selectedDate: toMoment(selectedDate).format(ISO_SHORT),
    startDate: startOfWeek(selectedDate),
  }
}

/**
 * Convert a value to a Moment object.
 * @param value A value that can be converted into a Moment object. 
 * If no value is provided a new moment is created that represents the
 * current time.
 * @param format An optional moment format string.
 */
export function toMoment(value?: MomentConvertable, format?: string) {
  if (typeof value === 'number') {
    return moment(new Date(value))
  } else if (typeof value === 'string') {
    return moment(value, format || moment.ISO_8601)
  }
  return moment(value)
}
