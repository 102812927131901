/**
 * Request an API bearer token.
 * @param {string} username
 * @param {string} password
 * @returns {Promise.<string>} token
 */
export function requestToken (username: string, password: string) {
  const url = '/api/token'
  const init = {
    body: JSON.stringify({
      'grant_type': 'password',
      password,
      username,
    }),
    headers: {
      'Accept': 'vnd.intertech.v1',
      'Content-Type': 'application/json;charset=utf-8'
    },
    method: 'POST',
  }
  return fetch(url, init)
    .then(checkStatus)
    .then(resp => resp.json())
    .then(extractToken)
}

function checkStatus (response: Response) {
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }
  return response
}

function extractToken (body: any) {
  const token = body.access_token
  if (!token) {
    throw new Error('No access token')
  }
  return token
}
