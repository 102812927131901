import classNames from 'classnames';
import * as React from 'react';
import './Spinner.scss';

interface ISpinnerProps {
  small?: boolean
}

export const Spinner: React.SFC<ISpinnerProps> = ({ small }) => (
  <div className={classNames('spinner', { 'spinner-small': small })}>
    <div className='bounce1' />
    <div className='bounce2' />
    <div className='bounce3' />
  </div>
)
