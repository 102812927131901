import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DeleteForever from '@material-ui/icons/DeleteForever';
import classNames from 'classnames';
import * as React from 'react';

const styles = (theme: Theme) => createStyles({
  root: {
  },
  confirm: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main
    }
  },
  delete: {
    fontSize: 20,
    marginLeft: theme.spacing.unit,
  }
})

interface IConfirmDeleteButtonProps extends WithStyles<typeof styles> {
  onClick: () => void
}

interface IConfirmDeleteButtonState {
  needsConfirmation: boolean
}

class ConfirmDeleteButton extends React.Component<IConfirmDeleteButtonProps, IConfirmDeleteButtonState> {
  state = {
    needsConfirmation: false
  }

  render() {
    const { classes } = this.props
    const { needsConfirmation } = this.state

    // The autoFocus+blur trick only works in Android. Safari on iOS does not support autoFocus.
    const button = needsConfirmation
      ? (
        <Button
          autoFocus
          color='primary'
          variant='contained'
          className={classNames(classes.root, classes.confirm)}
          onClick={this.handleConfirmedClick}
          onBlur={this.handleBlur}
        >
          Confirm
          <DeleteForever className={classes.delete} />
        </Button>
      )
      : <Button
        color='primary'
        className={classes.root}
        onClick={this.handleUnconfirmedClick}>Delete</Button>

    return button
  }

  private handleConfirmedClick = (e: any) => {
    const { onClick } = this.props

    e.preventDefault()
    onClick()
  }

  private handleUnconfirmedClick = () => {
    this.setState({ needsConfirmation: true })
  }

  private handleBlur = () => {
    this.setState({ needsConfirmation: false })
  }
}

export default withStyles(styles)(ConfirmDeleteButton)