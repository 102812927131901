import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as React from 'react';
import { IPto } from '../../models';
import { round } from '../../utils/numbers';

const styles = (theme: Theme) => createStyles({
  root: {
    margin: theme.spacing.unit * 2
  },
  balance: {
    fontWeight: 700
  }
})

interface IVacationReportRowProps {
  hours: number
  description: string
  className?: string
}

const VacationReportRow: React.SFC<IVacationReportRowProps> = ({ hours, description, className }) =>
  <TableRow>
    <TableCell className={className}>{description}</TableCell>
    <TableCell className={className} numeric>{round(hours, 2)}</TableCell>
    <TableCell className={className} numeric>{round(hours / 8, 2)}</TableCell>
  </TableRow>

interface IVactionReportProps extends WithStyles<typeof styles> {
  pto: IPto
}

const VacationReport: React.SFC<IVactionReportProps> = ({ pto, classes }) => {
  const { vacationTime: { granted, carriedOver, used, scheduledNotTaken } } = pto
  const balance = granted + carriedOver - used - scheduledNotTaken

  return (
    <Paper className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Vacation Time</TableCell>
            <TableCell numeric>Hours</TableCell>
            <TableCell numeric>Days</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <VacationReportRow description='Granted' hours={granted} />
          <VacationReportRow description='Carry-over' hours={carriedOver} />
          <VacationReportRow description='Used' hours={used} />
          <VacationReportRow description='Scheduled' hours={scheduledNotTaken} />
          <VacationReportRow description='Balance' hours={balance} className={classes.balance} />
        </TableBody>
      </Table>
    </Paper>
  )
}

export default withStyles(styles)(VacationReport)