import LinearProgress from '@material-ui/core/LinearProgress';
import * as React from 'react';
import { IFullBookedTimeEntry, MomentConvertable, TimesheetMode } from '../../models';
import { nextDay, nextWeek, previousDay, previousWeek } from '../../utils/dateUtils';
import { dateSearch, searchDate } from '../../utils/routingHelpers';
import { DateRangeBar } from '../DateRangeBar';
import { ErrorMessage } from '../Message/Message';
import { ITimeEntriesQueryResult, TimeEntriesQuery } from '../TimeEntriesQuery/TimeEntriesQuery';
import { TimeEntryDialog } from '../TimeEntryDialog';
import { IDateRangeProps, withDateRange } from '../WithDateRange';
import './Timesheet.scss';
import { TimesheetBodyDay } from './TimesheetBodyDay';
import { TimesheetBodyWeek } from './TimesheetBodyWeek';

interface ITimesheetRouteParams {
  mode: TimesheetMode
}

interface ITimesheetProps extends IDateRangeProps<ITimesheetRouteParams> {
  selectedDate?: string
}

interface ITimesheetState {
  showDialog: boolean
  selectedBookedTimeEntry: IFullBookedTimeEntry | null
}

class Timesheet extends React.Component<ITimesheetProps, ITimesheetState> {
  constructor(props: ITimesheetProps) {
    super(props)
    this.state = {
      selectedBookedTimeEntry: null,
      showDialog: false,
    }
  }

  openTimeEntryDialog = (selectedBookedTimeEntry?: IFullBookedTimeEntry) => {
    this.setState({
      selectedBookedTimeEntry: selectedBookedTimeEntry || null,
      showDialog: true,
    })
  }

  closeTimeEntryDialog = () => {
    this.setState({ showDialog: false })
  }

  handleOnNext = () => {
    const { match, location, history } = this.props
    const mode = match.params.mode
    const selectedDate = searchDate(location.search)
    const next = mode === 'day' ? nextDay(selectedDate) : nextWeek(selectedDate)
    history.push({ search: dateSearch(next.selectedDate) })
  }

  handleOnPrevious = () => {
    const { match, location, history } = this.props
    const mode = match.params.mode
    const selectedDate = searchDate(location.search)
    const prev = mode === 'day' ? previousDay(selectedDate) : previousWeek(selectedDate)
    history.push({ search: dateSearch(prev.selectedDate) })
  }

  handleWeekRowClick = (d: MomentConvertable) =>
    this.props.toDay(d)

  handleWeekNewEntryClick = (d: MomentConvertable) => {
    this.props.toWeek(d)
    this.setState({
      selectedBookedTimeEntry: null,
      showDialog: true,
    })
  }

  render() {
    const { selectedBookedTimeEntry, showDialog } = this.state
    const { dateRange, match: { params: { mode } } } = this.props
    const { selectedDate, startDate, endDate } = dateRange

    let barStartDate: string
    let barEndDate: string = ''
    if (mode === 'day') {
      barStartDate = selectedDate
    } else {
      barStartDate = startDate
      barEndDate = dateRange.endDate
    }

    return (

      < div className='timesheet' >
        <DateRangeBar
          endDate={barEndDate}
          onNext={this.handleOnNext}
          onPrevious={this.handleOnPrevious}
          startDate={barStartDate}
        />
        <TimeEntriesQuery
          variables={{ startDate, endDate }}
        >
          {({ loading, error, data }: ITimeEntriesQueryResult) => {
            if (loading) {
              return <LinearProgress />
            }
            if (error || !data) {
              return <ErrorMessage error={error}>Error Loading Time Entries</ErrorMessage>
            }

            return (
              <div className='timesheet-body-container'>
                {mode === 'day'
                  ? <TimesheetBodyDay
                    bookedTimeEntries={data.my.bookedTimeEntries}
                    startDate={startDate}
                    selectedDate={selectedDate}
                    endDate={endDate}
                    onClickNewEntry={this.openTimeEntryDialog}
                    onClickRow={this.openTimeEntryDialog}
                  />
                  : <TimesheetBodyWeek
                    bookedTimeEntries={data.my.bookedTimeEntries}
                    startDate={startDate}
                    endDate={endDate}
                    onRowClick={this.handleWeekRowClick}
                    onNewEntry={this.handleWeekNewEntryClick}
                  />
                }
              </div>
            )
          }}
        </TimeEntriesQuery>
        <TimeEntryDialog
          bookedTimeEntry={selectedBookedTimeEntry}
          endDate={endDate}
          open={showDialog}
          onClose={this.closeTimeEntryDialog}
          selectedDate={selectedDate}
          startDate={startDate}
        />
      </div>
    )
  }
}

export default withDateRange(Timesheet)