import gql from "graphql-tag";
import { Mutation, MutationFn, MutationResult } from "react-apollo";

const DELETE_TIMEENTRY_QUERY = gql`
mutation delete($entryId: ID!) {
	deleteBookedTimeEntry(entryId: $entryId) {
		id
	}
}
`

export interface IDeleteTimeEntryData {
  deleteBookedTimeEntry: {
    id: number
  }
}

export interface IDeleteTimeEntryVariables {
  entryId: number
}

export type DeleteTimeEntryMutationFn = MutationFn<IDeleteTimeEntryData, IDeleteTimeEntryVariables>

export type DeleteTimeEntryMutationResult = MutationResult<IDeleteTimeEntryData>

export class DeleteTimeEntryMutation extends Mutation<IDeleteTimeEntryData, IDeleteTimeEntryVariables> {
  static defaultProps = {
    mutation: DELETE_TIMEENTRY_QUERY
  }
}