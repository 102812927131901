import { Action, Location } from 'history';
import * as queryString from 'query-string';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppInsights } from '../../utils/appInsights';

interface IRouteListenerProps extends RouteComponentProps<{}> {
}

class RouteListener extends React.Component<IRouteListenerProps, any> {
  unlisten?: () => void

  componentDidMount() {
    this.unlisten = this.props.history.listen(this.onRouteChange)
  }

  componentWillUnmount() {
    const { unlisten } = this
    if (unlisten) {
      unlisten()
    }
  }

  public render() {
    return null
  }

  private onRouteChange = (location: Location, action: Action) => {
    const properties = {
      ...queryString.parse(location.search),
      action
    }

    AppInsights.trackPageView(undefined, location.pathname, properties, undefined, 0)
  }
}

export default withRouter(RouteListener)