import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import { dueDateTime } from '../../utils/dateUtils';
import './AllocationSummary.scss';

const styles = createStyles({
  root: {}
})

interface IAllocationSummaryProps extends WithStyles<typeof styles> {
  className?: string
  billableHours?: number
  nonBillableHours?: number
  isSubmitted?: boolean
}

class AllocationSummary extends React.Component<IAllocationSummaryProps> {
  static defaultProps = {
    billableHours: 0,
    isSubmitted: false,
    nonBillableHours: 0,
  }

  render() {
    const { billableHours, nonBillableHours, isSubmitted, className: classNameProp, classes } = this.props
    const last = dueDateTime().format('M/D/YYYY @ h:mmA')

    const className = classNames(
      'allocation-summary',
      classes.root,
      classNameProp
    )

    return (
      <div className={className}>
        <table className='left'>
          <tbody>
            <tr>
              <td className='strong'>{billableHours}</td>
              <td>Billable</td>
            </tr>
            <tr>
              <td className='strong'>{nonBillableHours}</td>
              <td>Non-Billable</td>
            </tr>
          </tbody>
        </table>
        <table className='right'>
          <tbody>
            <tr>
              <td className='due'>Due {last}</td>
            </tr>
            <tr>
              <td className='strong'>{isSubmitted ? 'Locked' : ''}</td>
            </tr>
          </tbody>
        </table>
      </div >
    )
  }
}

export default withStyles(styles)(AllocationSummary)