import gql from "graphql-tag"
import moment from 'moment'
import { Query, QueryResult } from "react-apollo"
import { IPto, IUser } from '../../models'

const currentYear = moment().format('YYYY')

const PTO_QUERY = gql`
query allocations($year: String!) {
  my {
    pto(year: $year) {
      vacationTime {
        granted
        used
        carriedOver
        scheduledNotTaken
      }
      sickTime {
        granted
        carriedOver
        used
      }
    }
  }
}
`

interface IPtoUser extends IUser {
  pto: IPto
}

interface IPtoQueryData {
  my: IPtoUser
}

export interface IPtoQueryResult extends QueryResult<IPtoQueryData> {
}

export class PtoQuery extends Query<IPtoQueryData> {
  static defaultProps = {
    query: PTO_QUERY,
    variables: {
      year: currentYear
    }
  }
}