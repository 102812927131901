import classnames from 'classnames'
import * as React from 'react'
import './Message.scss'

interface IMessageProps {
  isError?: boolean
}

export const Message: React.SFC<IMessageProps> = ({ children, isError }) => (
  <div className={classnames('message', {
    'text-danger': isError,
    'text-primary': !isError,
  })}>
    {children}
  </div>
)

interface IErrorMessageProps {
  error?: Error
}

export const ErrorMessage: React.SFC<IErrorMessageProps> = ({ children, error }) => (
  <Message isError={true}>
    {children || (error && error.message)}
  </Message>
)
