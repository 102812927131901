import gql from 'graphql-tag';
import { Query, QueryResult } from 'react-apollo';
import { IUser } from '../../models';

const USER_QUERY = gql`
query user {
  my {
    name
    email
  }
}
`

interface IUserQueryData {
  my?: IUser
}

export interface IUserQueryResult extends QueryResult<IUserQueryData> { }

export class UserQuery extends Query<IUserQueryData>{ 
  static defaultProps = {
    query: USER_QUERY
  }
}