import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { IFullBookedTimeEntry, MomentConvertable } from '../../models';
import { dateArray } from '../../utils/dateUtils';
import { isReadOnly, timeEntryRangeHours } from '../../utils/timeEntryUtils';
import { CopyLastWeekButton } from '../CopyLastWeekButton';
import { TimesheetAllocation } from '../TimesheetAllocation/TimesheetAllocation';
import './Timesheet.scss';
import { TimesheetCellHours } from './TimesheetCells';
import { TimesheetRowWeek } from './TimesheetRows';

interface ITimesheetBodyWeekProps {
  bookedTimeEntries: IFullBookedTimeEntry[]
  startDate: MomentConvertable
  endDate: MomentConvertable
  onRowClick: (date: MomentConvertable) => void
  onNewEntry: (date: MomentConvertable) => void
}

export const TimesheetBodyWeek: React.SFC<ITimesheetBodyWeekProps> = ({ bookedTimeEntries, startDate, endDate, onNewEntry, onRowClick }) => {
  const weeklyHours = timeEntryRangeHours(bookedTimeEntries)
  const dates = dateArray(startDate, endDate)
  const isSubmitted = isReadOnly(bookedTimeEntries)
  const hasEntries = bookedTimeEntries.length > 0

  return (
    <div className='timesheet-body timesheet-body-week'>
      <div className='timesheet-body-rows'>
        {!hasEntries &&
          <React.Fragment>
            <Typography
              align='center'
              className='no-entries'
              variant='subheading'
            >
              No entries for this week
            </Typography>
            <CopyLastWeekButton />
          </React.Fragment>
        }
        {hasEntries && dates.map(date => (
          <TimesheetRowWeek
            key={date}
            bookedTimeEntries={bookedTimeEntries}
            date={date}
            isSubmitted={isSubmitted}
            onClick={onRowClick}
            onNewEntry={onNewEntry}
          />
        ))}
      </div>
      <div className='timesheet-row timesheet-row-total'>
        <TimesheetCellHours hours={weeklyHours.totalHours} />
        <TimesheetAllocation bookedTimeEntries={bookedTimeEntries} compact={true} />
      </div>
    </div >
  )
}
