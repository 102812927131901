// tslint:disable:jsx-no-lambda
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { MutationUpdaterFn } from 'apollo-client';
import classNames from 'classnames';
import gql from 'graphql-tag';
import * as React from 'react';
import { Mutation, MutationFn, MutationResult } from 'react-apollo';
import { ISO_SHORT, toMoment } from '../../utils/dateUtils';
import { ActivityButton } from '../ActivityButton';
import { FULL_BOOKED_TIME_ENTRY_FRAGMENT, ITimeEntriesQueryData, TIME_ENTRIES_QUERY } from '../TimeEntriesQuery';
import { IDateRangeProps, withDateRange } from '../WithDateRange';

const CREATE_VACATION_QUERY = gql`
mutation vacation($date: String!) {
  createVacationBookedTimeEntry(date: $date, hours: 8) {
    ...FullBookedTimeEntry
  }
}
${FULL_BOOKED_TIME_ENTRY_FRAGMENT}
`

const CREATE_SICK_QUERY = gql`
mutation sick($date: String!) {
  createSickBookedTimeEntry(date: $date, hours: 8) {
    ...FullBookedTimeEntry
  }
}
${FULL_BOOKED_TIME_ENTRY_FRAGMENT}
`

const styles = (theme: Theme) => createStyles({
  root: {
    borderRadius: 0,
    backgroundColor: theme.palette.grey['300']
  }
})

type QuickEntryType = 'vacation' | 'sick'

interface IQuickEntryButtonProps extends IDateRangeProps, WithStyles<typeof styles> {
  className?: string
  entryType: QuickEntryType
}

class QuickEntryButton extends React.Component<IQuickEntryButtonProps> {
  render() {
    const { entryType } = this.props
    const query = entryType === 'vacation'? CREATE_VACATION_QUERY : CREATE_SICK_QUERY
    return (
      <Mutation mutation={query} update={this.updateCache}>
        {this.renderButton}
      </Mutation>
    )
  }

  renderButton = (save: MutationFn, { loading }: MutationResult) => {
    const { classes, className, entryType, dateRange: { selectedDate } } = this.props
    const variables = { date: toMoment(selectedDate).format(ISO_SHORT) }
    const rootClassName = classNames(classes.root, className)

    return (
      <ActivityButton
        busy={loading}
        className={rootClassName}
        onClick={() => save({ variables })}
      >
        {entryType} Today
      </ActivityButton >
    )
  }

  updateCache: MutationUpdaterFn = (cache, { data }) => {
    if (!data) {
      return
    }

    const { dateRange: { startDate, endDate } } = this.props
    const query = TIME_ENTRIES_QUERY
    const variables = { startDate, endDate }

    let results = cache.readQuery<ITimeEntriesQueryData>({
      query,
      variables
    })

    if (!results) {
      return
    }

    const newTimeEntry = data.createSickBookedTimeEntry || data.createVacationBookedTimeEntry

    // We need to pass a new object or the cache won't properly update
    // Components watching this query will not re-render
    results = {
      ...results,
      my: {
        ...results.my,
        bookedTimeEntries: [...results.my.bookedTimeEntries, newTimeEntry]
      }
    }

    cache.writeQuery({
      data: results,
      query,
      variables
    })
  }
}

export default withDateRange(withStyles(styles)(QuickEntryButton))
