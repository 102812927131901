import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { MutationUpdaterFn } from 'apollo-client';
import gql from 'graphql-tag';
import * as React from 'react';
import { Mutation, MutationFn, MutationResult } from 'react-apollo';
import { IFullBookedTimeEntry } from '../../models';
import { ActivityButton } from '../ActivityButton';
import { FULL_BOOKED_TIME_ENTRY_FRAGMENT } from '../TimeEntriesQuery';
import { ITimeEntriesQueryData, TIME_ENTRIES_QUERY } from '../TimeEntriesQuery/TimeEntriesQuery';
import { IDateRangeProps, withDateRange } from '../WithDateRange';

const COPY_LAST_WORKDAY_MUTATION = gql`
mutation copyLast($destinationDate: String!) {
	copyLastWorkday(destinationDate: $destinationDate) {
    ...FullBookedTimeEntry
	}
}
${FULL_BOOKED_TIME_ENTRY_FRAGMENT}
`

interface ICopyLastWorkdayData {
  copyLastWorkday: IFullBookedTimeEntry[]
}

interface ICopyLastWorkdayVariables {
  destinationDate: string
}

type CopyLastWorkdayMutationFn = MutationFn<ICopyLastWorkdayData, ICopyLastWorkdayVariables>
type CopyLastWorkdayMutationResult = MutationResult<ICopyLastWorkdayData>

const styles = (theme: Theme) => createStyles({
  root: {
    borderRadius: 0,
    backgroundColor: theme.palette.grey['300']
  }
})

interface ICopyLastWorkdayButtonProps extends IDateRangeProps, WithStyles<typeof styles> { }

// tslint:disable:jsx-no-lambda
class CopyLastWorkdayButton extends React.Component<ICopyLastWorkdayButtonProps> {
  render() {
    return (
      <Mutation mutation={COPY_LAST_WORKDAY_MUTATION} update={this.updateCache}>
        {this.renderButton}
      </Mutation>
    )
  }

  renderButton = (copy: CopyLastWorkdayMutationFn, { loading }: CopyLastWorkdayMutationResult) => {
    const { classes, dateRange: { selectedDate } } = this.props
    const variables: ICopyLastWorkdayVariables = {
      destinationDate: selectedDate
    }
    return (
      <ActivityButton
        busy={loading}
        className={classes.root}
        
        onClick={() => copy({ variables })}
      >
        Copy Last Workday
      </ActivityButton>
    )
  }

  updateCache: MutationUpdaterFn<ICopyLastWorkdayData> = (cache, { data }) => {
    const { dateRange: { startDate, endDate } } = this.props
    const query = TIME_ENTRIES_QUERY
    const variables = { startDate, endDate }

    // Read the current entries for TIME_ENTRIES_QUERY 
    const results = cache.readQuery<ITimeEntriesQueryData>({
      query,
      variables
    })

    if (!results || !data) {
      return
    }

    const next = {
      ...results,
      my: {
        ...results.my,
        bookedTimeEntries: [...results.my.bookedTimeEntries, ...data.copyLastWorkday]
      }
    }

    // Write a new array of time entries for this query
    cache.writeQuery({
      data: next,
      query,
      variables
    })
  }
}

export default withStyles(styles)(withDateRange(CopyLastWorkdayButton))