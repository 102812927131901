import { clearToken } from './storage'

/**
 * Remove the bearer token from local storage and force the page
 * to reload. This will allow the Login component to do its thing.
 */
export function logout () {
  clearToken()
  document.location.reload()
}
