import BottomNavigationAction, { BottomNavigationActionProps } from '@material-ui/core/BottomNavigationAction';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface IButtonBarActionProps extends BottomNavigationActionProps {
  search: string
}

export class BottomBarAction extends React.Component<IButtonBarActionProps> {
  renderLink = (itemProps: any) =>
    <Link {...itemProps} to={{ pathname: this.props.value, search: itemProps.search }} />

  render() {
    return (
      <BottomNavigationAction
        {...this.props}
        component={this.renderLink}
      />
    )
  }
}