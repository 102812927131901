import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import * as React from 'react';
import { logout } from '../../utils/sessionUtils';
import { UserQuery } from '../Auth/UserQuery';

interface IAccountMenuProps {
  onClose?: () => void
}

interface IAccountMenuState {
  anchorEl: Element | null
}

export class AccountMenu extends React.Component<IAccountMenuProps, IAccountMenuState>{
  state = {
    anchorEl: null
  }

  handleMenu = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    const { onClose } = this.props
    this.setState({ anchorEl: null }, () => {
      if (onClose) {
        onClose()
      }
    });
  }

  handleBackOffice = () => {
    window.open('https://backoffice.intertech.com', 'blank');
  }

  handleFeedBack = () => {
    window.open('https://gitlab.com/intertech/TimeEntryPwa/issues/new', 'blank')
  }

  handleReload = () => {
    window.location.reload()
  }

  handleLogout = () => logout()

  render() {
    const { anchorEl } = this.state
    const menuOpen = Boolean(anchorEl)

    return (
      <ClickAwayListener onClickAway={this.handleClose}>
        <div>
          <IconButton
            aria-owns={window.open ? 'menu-appbar' : undefined}
            aria-haspopup='true'
            onClick={this.handleMenu}
            color='inherit'
          >
            <AccountCircle />
          </IconButton>
          <UserQuery>
            {({ data }) => {
              return (
                <Menu
                  id='menu-appbar'
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={menuOpen}
                >
                  <MenuItem disabled={true}>{data?.my?.name}</MenuItem>
                  <MenuItem onClick={this.handleBackOffice}>Back Office</MenuItem>
                  <MenuItem onClick={this.handleFeedBack}>Feedback</MenuItem>
                  <MenuItem onClick={this.handleReload} divider>Reload</MenuItem>                  
                  <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                </Menu>
              )
            }}
          </UserQuery>
        </div>
      </ClickAwayListener>
    )
  }
}