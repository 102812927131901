import * as d3 from 'd3';
import * as React from 'react';
import './RingGraph.scss';

interface IRingGraphPoint {
  label: string
  value: number
}

interface IRingGraphProps {
  data: IRingGraphPoint[]
}

const colors = d3.scaleOrdinal(d3.schemeCategory10)
const arcGenerator: any = d3.arc()
  .innerRadius(50)
  .outerRadius(90)
  .startAngle(d => d.startAngle)
  .endAngle(d => d.endAngle)

export class RingGraph extends React.Component<IRingGraphProps> {
  svg?: SVGSVGElement
  group: any

  setContainer = (ref: SVGSVGElement) => { this.svg = ref }

  render() {
    const { data } = this.props

    const arcs = d3.pie()(data.map(d => d.value))
      .map(a => arcGenerator(a))

    return (
      <div className='ring-graph'>
        <div className='ring-graph-svg'>
          <svg
            ref={this.setContainer}
            viewBox='-100 -100 200 200'
            preserveAspectRatio="xMidYMid meet"
          >
            <g>
              {arcs.map((d, i) => (
                <path
                  key={i}
                  d={d}
                  fill={colors(i.toString())}
                />
              ))}
            </g>
          </svg>
        </div>
        <div className='ring-graph-legend'>
          <ul>
            {
              data.map((d, i) => (
                <li key={i}>
                  {d.label} ({d.value})
                  <span style={{ backgroundColor: colors(i.toString()) }} />
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    )
  }
}
