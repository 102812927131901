import { Moment } from 'moment';
import * as queryString from 'query-string';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IDateRange, MomentConvertable } from '../../models';
import { getDisplayName } from '../../utils/componentHelpers';
import { getWeek, ISO_SHORT, today, toMoment } from '../../utils/dateUtils';

export function dateSearch(value: MomentConvertable) {
  const m = toMoment(value)
  const date = m.isValid() ? m.format(ISO_SHORT) : today()
  return queryString.stringify({ date })
}

export function searchDate(search: string): string | undefined | null {
  const params = queryString.parse(search)
  if (Array.isArray(params.date)) {
    return params.date.join(',')
  }
  return params.date
}

export interface IDateRangeProps<TRouteParams = {}> extends RouteComponentProps<TRouteParams> {
  dateRange: IDateRange
  toToday: () => void
  toThisWeek: () => void
  toWeek: (date: MomentConvertable) => void
  toDay: (date: MomentConvertable) => void
  nextDay: () => void
  previousDay: () => void
  nextWeek: () => void
  previousWeek: () => void
}

export const withDateRange = <TWrappedComponentProps extends IDateRangeProps<TRouteParams>, TRouteParams = {}>(WrappedComponent: React.ComponentType<TWrappedComponentProps>) => {
  type WithDateRangeProps = Subtract<TWrappedComponentProps, IDateRangeProps<TRouteParams>> & RouteComponentProps<TRouteParams>

  class WithDateRange extends React.Component<WithDateRangeProps> {
    static displayName = `WithDateRange(${getDisplayName(WrappedComponent)})`

    today = () => {
      this.day(today())
    }

    thisWeek = () => {
      const { history } = this.props
      this.week(today())
      history.push({ pathname: '/timesheet/week' })
    }

    day = (date: MomentConvertable) => {
      const { history } = this.props
      const m = this.toMoment(date)
      const search = this.toSearch(m)
      history.push({ pathname: '/timesheet/day', search })
    }

    week = (date: MomentConvertable) => {
      const { history } = this.props
      const m = this.toMoment(date)
      const search = this.toSearch(m)
      history.push({ search })
    }

    nextDay = () => {
      const date = this.currentDate()
      const next = toMoment(date).startOf('day').add(1, 'day')
      this.day(next)
    }

    previousDay = () => {
      const date = this.currentDate()
      const next = toMoment(date).startOf('day').subtract(1, 'day')
      this.day(next)
    }

    nextWeek = () => {
      const date = this.currentDate()
      const next = toMoment(date).startOf('day').add(1, 'week')
      this.week(next)
    }

    previousWeek = () => {
      const date = this.currentDate()
      const next = toMoment(date).startOf('day').subtract(1, 'week')
      this.week(next)
    }

    render() {
      const date = this.currentDate()
      const week = getWeek(date)
      const injectedProps = {
        toToday: this.today,
        toThisWeek: this.thisWeek,
        toDay: this.day,
        toWeek: this.week,
        nextDay: this.nextDay,
        previousDay: this.previousDay,
        nextWeek: this.nextWeek,
        previousWeek: this.previousWeek,
        dateRange: week,
      }

      const ComponentHack: any = WrappedComponent
      return <ComponentHack {...this.props} {...injectedProps} />
    }

    private currentDate() {
      const { location } = this.props
      const params = queryString.parse(location.search)
      if (Array.isArray(params.date)) {
        return params.date.join(',')
      }
      if (params.date === null) {
        return undefined
      }
      return params.date
    }

    private toMoment(date: MomentConvertable) {
      const m = toMoment(date)
      if (!m.isValid()) {
        throw new Error(`Invalid date "${date}". Must be a string in form YYYY-MM-DD.`)
      }
      return m
    }

    private toSearch(date: Moment) {
      return queryString.stringify({ date: date.format(ISO_SHORT) })
    }
  }

  return withRouter(WithDateRange)
}

