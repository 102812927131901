import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';
import * as storage from '../../utils/storage';
import { Login } from '../Login';
import { IUserQueryResult, UserQuery } from './UserQuery';

const styles = createStyles({
  root: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

interface IAuthState {
  token: string | null
}

class Auth extends React.Component<WithStyles<typeof styles>, IAuthState> {
  state = {
    token: storage.getToken()
  }

  handleLogin = () => {
    this.setState({
      token: storage.getToken()
    })
  }

  render() {
    const { token } = this.state

    if (token) {
      return (
        <UserQuery>
          {this.renderApp}
        </UserQuery>
      )
    }

    return <Login onLogin={this.handleLogin} />
  }

  renderApp = ({ loading }: IUserQueryResult) => {
    const { classes } = this.props
    return loading
      ? <div className={classes.root}><CircularProgress /></div>
      : this.props.children
  }
}

export default withStyles(styles)(Auth)
