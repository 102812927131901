import { AppInsights } from 'applicationinsights-js';

const instrumentationKey = 'b830df88-b5c4-4fc2-a8f9-a524d261b2b8'

if (instrumentationKey) {
  const { downloadAndSetup } = AppInsights
  if (downloadAndSetup) {
    downloadAndSetup({
      instrumentationKey,
      maxBatchInterval: 5000, // 5s
    })
  }
}

export { AppInsights };

