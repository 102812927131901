import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { isIosApp, isIPhoneX } from '../../utils/deviceDetection';
import { BottomBar } from '../BottomBar';
import { Dashboard } from '../Dashboard';
import { Reports } from '../Reports';
import { RouteListener } from '../RouteListener';
import { Timesheet } from '../Timesheet';
import { TopBar } from '../TopBar';
import RedirectToLast from './RedirectToLastLocation';
import SetLastLocation from './SetLastLocation';

const MIN_APP_HEIGHT = 620

// Add a few pixels to the height of the footer to allow for the home indicator
const footerHeight = isIPhoneX() ? 68 : 56

/**
 * If we are running on iOS as a standalone app then use position fixed
 * on html and body when in portrait mode. This prevents the annoying bounce
 * as you pull down.
 */
const preventIosBounce = (): Record<string, CSSProperties> => {
  const position = isIosApp() && window.screen.height >= MIN_APP_HEIGHT
    ? 'fixed'
    : 'static'
  return {
    '@media (orientation: portrait)': { position }
  }
}

const styles = (theme: Theme) => createStyles({
  '@global': {
    html: {
      height: '100%',
      width: '100%',
      ...preventIosBounce(),
    },
    body: {
      height: '100%',
      width: '100%',
      ...preventIosBounce(),
    },
    '.app-root': {
      height: '100%',
      width: '100%',
    }
  },
  root: {
    position: 'relative',
    height: '100%',
    minHeight: MIN_APP_HEIGHT,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      width: theme.breakpoints.values.md,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    height: footerHeight,
    width: '100%',
  },
  content: {
    position: 'absolute',
    bottom: footerHeight,
    width: '100%',
    top: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      top: 48
    },
    [theme.breakpoints.up('sm')]: {
      top: 64
    }
  }
})

interface IAppContainerProps extends WithStyles<typeof styles> {
}

export const App = withStyles(styles)(({ classes }: IAppContainerProps) => (
  <div className={classes.root}>
    <TopBar />
    <div className={classes.content}>
      <Switch>
        <Route path='/dashboard' component={Dashboard} />
        <Route path='/timesheet/:mode' component={Timesheet} />
        <Route path='/reports' component={Reports} />
        <RedirectToLast defaultPath='/timesheet/day' />
      </Switch>
    </div>
    <BottomBar className={classes.footer} />
    <SetLastLocation />
    <RouteListener />
  </div>
))
