import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import classNames from 'classnames';
import * as React from 'react';

const styles = (theme: Theme) => createStyles({
  root: {
  },
  fill: {
    flex: 1,
    minWidth: '100px',
    maxWidth: '200px'
  },
  input: {
    textAlign: 'center'
  },
  button: {
    backgroundColor: theme.palette.grey[200],
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  }
})

type INumericChangeEventHandler = (value: number) => void

interface INumericInputProps extends WithStyles<typeof styles> {
  max: number
  min: number
  onChange: INumericChangeEventHandler
  step: number
  value: number
  className?: string
}

class NumericInput extends React.Component<INumericInputProps> {
  static defaultProps = {
    step: 1,
    max: Infinity,
    min: -Infinity,
    value: 0
  }

  render() {
    const { value, classes, className } = this.props
    const rootClassName = classNames(classes.root, className)

    return (
      <Grid
        container
        alignItems='center'
        justify='center'
        spacing={16}
        className={rootClassName}
      >
        <Grid item>
          <IconButton
            aria-label='Reduce Hours'
            className={classes.button}
            onClick={this.decrement}
          >
            <Remove />
          </IconButton>
        </Grid>
        <Grid item className={classes.fill}>
          <TextField
            onChange={this.handleChange}
            type='number'
            label='Hours'
            value={value}
            variant='outlined'
            inputProps={{
              className: classes.input,
              pattern: '[0-9]*',
            }}
          />
        </Grid>
        <Grid item>
          <IconButton
            aria-label='Increase Hours'
            className={classes.button}
            onClick={this.increment}
          >
            <Add />
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props
    // Parse the value here to prevent entry of letters in the input
    const parsed = this.parse(e.target.value)
    onChange(this.clamp(parsed))
  }

  private increment = () => {
    const { onChange, step, value } = this.props
    const next = this.clamp(value + step)
    onChange(next)
  }

  private decrement = () => {
    const { onChange, step, value } = this.props
    const next = this.clamp(value - step)
    onChange(next)
  }

  private parse(value: string) {
    const parsed = parseFloat(value)
    return isNaN(parsed) ? 0 : parsed
  }

  private clamp(value: number) {
    const { max, min } = this.props
    if (value > max) { return max }
    if (value < min) { return min }
    return value
  }
}

export default withStyles(styles)(NumericInput)