import * as queryString from 'query-string';
import { MomentConvertable } from "../models";
import { ISO_SHORT, today, toMoment } from "./dateUtils";

// TODO: Move these helpers and settings into an HOC that can wrap anything

export function dateSearch(value: MomentConvertable) {
  const m = toMoment(value)
  const date = m.isValid() ? m.format(ISO_SHORT) : today()
  return queryString.stringify({ date })
}

export function searchDate(search: string): string | undefined {
  const params = queryString.parse(search)
  if (Array.isArray(params.date)) {
    return params.date.join(',')
  }
  if (params.date === null) {
    return undefined
  }
  return params.date
}