import classnames from 'classnames';
import * as React from 'react';
import Swipeable from 'react-swipeable';
import { IFullBookedTimeEntry, MomentConvertable } from '../../models';
import { timeEntryRangeHours } from '../../utils/timeEntryUtils';
import { TimesheetCellDate, TimesheetCellHours, TimesheetCellNewEntry, TimesheetCellTimeEntry, TimesheetCellTimeEntryDetail } from './TimesheetCells';
import './TimesheetRows.scss';

interface ITimesheetRowDayProps {
  bookedTimeEntry: IFullBookedTimeEntry
  onClick?: (bookedTimeEntry: IFullBookedTimeEntry) => void
}

export class TimesheetRowDay extends React.Component<ITimesheetRowDayProps> {

  handleClick = () => {
    const { onClick, bookedTimeEntry } = this.props
    if (onClick) {
      onClick(bookedTimeEntry)
    }
  }

  render() {
    const { bookedTimeEntry } = this.props
    const allocation = bookedTimeEntry.allocation || {}
    return (
      <Swipeable
        className={classnames('timesheet-row timesheet-row-day', { billable: allocation.billable, editable: !bookedTimeEntry.readonly })}
        onTap={this.handleClick}
        style={{
          display: 'flex',
          flexBasis: 'auto',
          flexGrow: bookedTimeEntry.hours,
          flexShrink: bookedTimeEntry.hours,
        }}
        trackMouse={true}
      >
        <TimesheetCellHours hours={bookedTimeEntry.hours} />
        <TimesheetCellTimeEntryDetail bookedTimeEntry={bookedTimeEntry} />
      </ Swipeable>
    )
  }
}

interface ITimesheetRowWeekProps {
  bookedTimeEntries: IFullBookedTimeEntry[],
  date: MomentConvertable,
  isSubmitted: boolean,
  onClick: (date: MomentConvertable) => void
  onNewEntry: (date: MomentConvertable) => void
}

export class TimesheetRowWeek extends React.Component<ITimesheetRowWeekProps> {
  handleClick = () => {
    const { onClick, date } = this.props
    onClick(date)
  }

  render() {
    const { bookedTimeEntries, date, onNewEntry, isSubmitted } = this.props
    const entries = bookedTimeEntries.filter(e => e.date === date)
    const hours = timeEntryRangeHours(entries)

    return (
      <div
        className={classnames('timesheet-row timesheet-row-week', { billable: entries.length > 0 })}
        onClick={this.handleClick}
      >
        <TimesheetCellDate date={date} />
        <TimesheetCellHours hours={hours.totalHours} />
        <div className='timesheet-row-time-entries'>
          {entries.map(e =>
            <TimesheetCellTimeEntry key={e.id} bookedTimeEntry={e} />
          )}
        </div>
        {entries.length === 0 && !isSubmitted &&
          <TimesheetCellNewEntry
            date={date}
            onClick={onNewEntry}
          />
        }
      </div>
    )
  }

}