import gql from "graphql-tag";
import { Query, QueryResult } from "react-apollo";
import { IFullBookedTimeEntry, IUser } from '../../models';

export const FULL_BOOKED_TIME_ENTRY_FRAGMENT = gql`
fragment FullBookedTimeEntry on BookedTimeEntry {
  id
  date
  hours
  note
  readonly
  allocation {
    id
    client
    project
    task
    active
    billable
    isIntertech
  }
}
`

export const TIME_ENTRIES_QUERY = gql`
query timeEntries ($startDate: String!, $endDate: String!) {
  my {
		bookedTimeEntries(startDate: $startDate, endDate: $endDate) {
      ...FullBookedTimeEntry
		}
	}
}
${FULL_BOOKED_TIME_ENTRY_FRAGMENT}
`

interface ITimeEntriesVariables {
  startDate: string
  endDate: string
}

interface IUserBookedTimeEntries extends IUser {
  bookedTimeEntries: IFullBookedTimeEntry[]
}

export interface ITimeEntriesQueryData {
  my: IUserBookedTimeEntries
}

export interface ITimeEntriesQueryResult extends QueryResult<ITimeEntriesQueryData, ITimeEntriesVariables> { }

export class TimeEntriesQuery extends Query<ITimeEntriesQueryData, ITimeEntriesVariables> {
  static defaultProps = {
    query: TIME_ENTRIES_QUERY
  }
}