import { InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { IAllocation, IFullBookedTimeEntry, MomentConvertable } from '../../models';
import { ISO_SHORT, toMoment } from '../../utils/dateUtils';
import { NumericInput } from '../NumericInput';
import { ISaveTimeEntryVariables } from '../SaveTimeEntryMutation';

type ChangeEventHandler = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement | HTMLInputElement>) => void

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    marginTop: theme.spacing.unit,
    minWidth: '100%',
  },
  textField: {
    width: '100%',
  },
})

interface ITimeEntryFormProps extends WithStyles<typeof styles> {
  allocations: IAllocation[]
  bookedTimeEntry: IFullBookedTimeEntry | null
  date: MomentConvertable
  onClose: () => void
  onChange: (value: ISaveTimeEntryVariables) => void
}

interface ITimeEntryFormState {
  client: string
  variables: ISaveTimeEntryVariables
}

class TimeEntryForm extends React.Component<ITimeEntryFormProps, ITimeEntryFormState> {
  constructor(props: ITimeEntryFormProps) {
    super(props)
    this.state = this.buildState(props)
  }

  componentWillReceiveProps(nextProps: ITimeEntryFormProps) {
    if (
      this.props.bookedTimeEntry !== nextProps.bookedTimeEntry ||
      this.props.date !== nextProps.date
    ) {
      this.setState(this.buildState(nextProps))
    }
  }

  buildState(props: ITimeEntryFormProps): ITimeEntryFormState {
    const bookedTimeEntry = props.bookedTimeEntry || { allocation: {} } as IFullBookedTimeEntry
    return {
      client: bookedTimeEntry.allocation.client || '',
      variables: {
        entry: {
          allocationId: bookedTimeEntry.allocation.id || '',
          date: toMoment(props.date).format(ISO_SHORT),
          hours: bookedTimeEntry.hours || 8,
          id: bookedTimeEntry.id,
          note: bookedTimeEntry.note || ''
        }
      }
    }
  }

  handleNoteChange: ChangeEventHandler = e => this.updateVariables('note', e.target.value)

  handleHoursChange = (value: number) => this.updateVariables('hours', value)

  handleClientChange: ChangeEventHandler = e => {
    const client = e.target.value
    const { allocations } = this.props
    const clientAllocations = allocations.filter(a => a.client === client)

    if (clientAllocations.length === 1) {
      const allocationId = clientAllocations[0].id
      this.setState(state => ({
        client,
        variables: {
          ...state.variables,
          entry: {
            ...state.variables.entry,
            allocationId
          }
        }
      }), this.raiseOnChange)

    } else {
      this.setState({ client }, this.raiseOnChange)
    }
  }

  handleAllocationChange: ChangeEventHandler = e => this.updateVariables('allocationId', e.target.value)

  // TODO: Unit test all of these state changes. Make sure onChange is called, etc.
  updateVariables(propertyName: string, value: any) {
    this.setState(state => ({
      variables: {
        entry: {
          ...state.variables.entry,
          [propertyName]: value
        }
      }
    }), this.raiseOnChange)
  }

  raiseOnChange = () => {
    this.props.onChange(this.state.variables)
  }

  render() {
    const { allocations, classes } = this.props
    const { client, variables } = this.state

    const entry = variables.entry
    const clients = allocations.map(a => a.client).filter(distinct).sort()
    const tasks = allocations.filter(a => a.client === client)

    return (
      <form className={classes.root}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor='client'>Client</InputLabel>
          <Select
            disabled={clients.length === 0}
            onChange={this.handleClientChange}
            required={true}
            value={client}
            inputProps={{
              name: 'client',
              id: 'client',
            }}
          >
            <MenuItem value=''><em>None</em></MenuItem>
            {clients.map(c => <MenuItem key={c} value={c}>{c}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor='task'>Task</InputLabel>
          <Select
            disabled={tasks.length === 0}
            onChange={this.handleAllocationChange}
            required={true}
            value={entry.allocationId}
            inputProps={{
              name: 'task',
              id: 'task',
            }}
          >
            <MenuItem value=''><em>None</em></MenuItem>
            {tasks.map(t => {
              const label = t.isIntertech
                ? ''
                : `${t.project} - `

              return (
                <MenuItem key={t.id} value={t.id}>
                  {label}{t.task}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <NumericInput
          className={classes.formControl}
          onChange={this.handleHoursChange}
          value={entry.hours}
          min={0}
          max={24}
          step={0.25}
        />
        <TextField
          className={classes.textField}
          label="Notes"
          margin="normal"
          multiline
          onChange={this.handleNoteChange}
          placeholder='A note is required'
          required={true}
          rows="4"
          value={entry.note}
        />
      </form>
    )
  }
}

function distinct(value: string, index: number, self: string[]) {
  return self.indexOf(value) === index
}

export default withStyles(styles)(TimeEntryForm)