import { createStyles, LinearProgress, Theme, WithStyles, withStyles } from '@material-ui/core';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IPtoQueryResult, PtoQuery } from './PtoQuery';
import SickTimeReport from './SickTimeReport';
import VacationReport from './VacationTimeReport';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[100],
    height: '100%',
    overflowY: 'auto',
  }
})

interface IReportsProps extends WithStyles<typeof styles>, RouteComponentProps<{}> { }

class Reports extends React.Component<IReportsProps> {
  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <PtoQuery>{this.renderPtoQuery}</PtoQuery>
      </div>
    )
  }

  renderPtoQuery = ({ data, loading, error }: IPtoQueryResult) => {
    if (error) {
      return <div>error</div>
    }
    if (loading) {
      return <LinearProgress />
    }

    const pto = (data && data.my.pto)
    // const { bookedTimeEntry, selectedDate, onClose } = this.props

    if (pto) {
      return (
        <>
          <VacationReport pto={pto} />
          <SickTimeReport pto={pto} />
        </>
      )
    } else {
      return <div>error</div>
    }
  }
}

export default withStyles(styles)(Reports)